import * as React from "react"
import { Link } from "gatsby"
import tw from 'twin.macro';
import GlobalStyles from '../styles/GlobalStyles';
import Teaser from '../images/arcade_teaser_web.jpg';

// markup
const NotFoundPage = () => {

    const style = {
        backgroundImage: "url(" + Teaser + ")",
        backgroundPosition: "center -100px",
        backgroundRepeat: "no-repeat"
    }

    const paragraphStyle = {
        top: "550px",
        position: "relative"
    }

    return (
        <GlobalStyles>
            <div tw="bg-black h-screen" style={style}>
                <div style={paragraphStyle}>
                    <p tw="text-white text-white opacity-40 text-center uppercase tracking-widest font-bold">NOT FOUND</p>
                    <p tw="text-white text-center mt-2 underline"><Link to="/">&larr; Try going home.</Link></p>
                </div>
            </div>
        </GlobalStyles>
    )
}

export default NotFoundPage
